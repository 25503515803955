import React from "react";

export default function ContactCard() {
  return (
    <section id="contact" className="contact section" data-aos="fade-up">
      <div
        className="container text-light py-4 d-flex flex-column flex-md-row justify-content-around align-items-center"
        style={{
          backgroundImage: "url('/assets/img/stats-bg.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "15px",
          //   height: "150px",
          marginTop: "-2%",
        }}
      >
        <div className="text-center text-md-start mb-4 mb-md-0">
          <h2 className="mb-0" style={{ color: "#fff" }}>
            Contact
          </h2>
          <p className="mb-0">Get in Touch with Us Today</p>
        </div>
        <div className="d-flex align-items-center mb-3 mb-md-0">
          <div
            className="bg-secondary1 text-white rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: "50px", height: "50px" }}
          >
            <i
              className="bi bi-telephone-fill fs-4"
              style={{ color: "#6e3fd7" }}
            ></i>
          </div>
          <div className="ms-2">
            <p className="mb-0" style={{ width: 170 }}>
              <b>+92 309 3482 063</b>
            </p>
            <p className="mb-0">
              <b>+92 333 8701 104</b>
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center mb-3 mb-md-0">
          <div
            className="bg-secondary1 text-white rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: "50px", height: "50px" }}
          >
            <i
              className="bi bi-envelope-at-fill fs-4"
              style={{ color: "#6e3fd7" }}
            ></i>
          </div>
          <div className="ms-2">
            <p className="mb-0" style={{ width: 170 }}>
              <b>info@techpulsar.com</b>
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div
            className="bg-secondary1 text-white rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: "50px", height: "50px" }}
          >
            <i className="bi bi-whatsapp fs-4" style={{ color: "#6e3fd7" }}></i>
          </div>
          <div className="ms-2">
            <p className="mb-0" style={{ width: 170 }}>
              <b>+92 309 3482 063</b>
            </p>
            <p className="mb-0">
              <b>+92 333 8701 104</b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
