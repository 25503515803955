import React from "react";
import ServiceCard from "../components/serviceCard";

const ServicesSection = () => {
  const services = [
    {
      iconClass: "fa-regular fa-object-group",
      title: "UI UX Design",
      description:
        "We specialize in designing and creating websites that make a remarkable impression, significantly impacting your sales through a strategic business partnership.",
      link: "/ui-ux-design",
    },
    {
      iconClass: "bi bi-wordpress",
      title: "Wordpress Development",
      description:
        "Provide custom-built websites tailored to your business needs. We ensure responsive design, easy content management, and seamless integration with essential plugins.",
      link: "/wordpress-development",
    },
    {
      iconClass: "fa-solid fa-bolt",
      title: "Search Engine Optimization",
      description:
        "Optimize your website to enhance its visibility and ranking on search engines, drive more organic traffic, improve your online presence & boosting your business’s credibility and reach.",
      link: "/seo",
    },
    {
      iconClass: "bi bi-code-slash",
      title: "Web Development",
      description:
        "Crafts tailored, high-performance websites, deliver exceptional user experiences and meet your business needs. We ensure your site is functional and optimized for success.",
      link: "/website-development",
    },
    {
      iconClass: "bi bi-box",
      title: "App Development",
      description:
        "Build user-friendly apps that drive engagement and meet your business goals. Our end-to-end development process ensures high-quality, scalable solutions for both iOS & Android.",
      link: "/app-development",
    },
    {
      iconClass: "bi bi-megaphone",
      title: "Digital Marketing",
      description:
        "Enhance your online presence with our tailored digital marketing strategies. We drive targeted traffic, boost conversions, and help your business grow across multiple platforms.",
      link: "/digital-marketing",
    },
    // {
    //   iconClass: "fa-solid fa-shield-virus",
    //   title: "Penetration Testing",
    //   description:
    //     "Identify and assess security weaknesses in your website or app by uncovering potential vulnerabilities, safeguard your digital assets against threats and ensure system's integrity.",
    //   link: "/services-details#pen-testing",
    // },
    // {
    //   iconClass: "fa-solid fa-layer-group",
    //   title: "Cloud & DevOps Services",
    //   description:
    //     "Our services include cloud migration, infrastructure management, and continuous integration/deployment to optimize your IT environment and accelerate project delivery.",
    //   link: "/services-details#cloud-devops",
    // },
  ];

  return (
    <section
      id="services"
      className="services section"
      // style={{ backgroundColor: "transparent" }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>
          Offering you the best IT Services And Solutions that move your
          business to the forefront of your industry.
        </p>
      </div>

      <div className="row gy-4 cards-wrapper">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            iconClass={service.iconClass}
            title={service.title}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
