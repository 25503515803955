import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";

export default function SeoPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    // Set the initial active tab based on the URL hash
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash); // Scroll to the section initially if there's a hash
    }
  }, []);

  useEffect(() => {
    // Update the active tab when the URL hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    // Smooth scrolling to the section
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // Delay setting activeTab to ensure scrolling completes
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>Search Engine Optimization | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar provides comprehensive SEO services to improve your website's visibility, ranking, and traffic through expert keyword research, on-page optimization, and link building."
        />
        <meta
          name="keywords"
          content="SEO services, search engine optimization, keyword research, on-page SEO, link building, website ranking"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://techpulsar.com/seo" />

        {/* <!-- Open Graph Tags --> */}
        <meta
          property="og:title"
          content="Search Engine Optimization (SEO) | TechPulsar"
        />
        <meta
          property="og:description"
          content="Improve your website's ranking and visibility with TechPulsar's SEO services, including keyword research, on-page optimization, and link building."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/seo.webp"
        />
        <meta property="og:url" content="https://techpulsar.com/seo" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          <h1>Search Engine Optimization (SEO)</h1>
          <p>Enhancing Your Online Visibility and Search Engine Rankings</p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / SEO</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to SEO Services</h3>
                <p>
                  Boost your online presence with TechPulsar's comprehensive SEO
                  services. Our team specializes in optimizing your website to
                  improve search engine rankings, drive more organic traffic,
                  and increase conversions. From keyword research to content
                  optimization, we tailor our strategies to your business needs.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/seo.webp"
                alt="SEO services"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: "3%",
                }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why SEO is Essential</h3>
                <p>
                  In the digital age, visibility on search engines like Google
                  is crucial for business success. SEO is a cost-effective way
                  to reach your target audience and achieve long-term growth.
                  Professional SEO ensures that your website is not only visible
                  but also relevant to the needs of your customers.
                </p>
                <p>
                  With over 90% of online experiences beginning with a search
                  engine, investing in SEO is essential for staying competitive
                  in your industry. Our expert team helps you capitalize on this
                  potential, driving more traffic and enhancing your online
                  presence.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Detailed SEO Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h4>1. Keyword Research & Strategy</h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We conduct thorough keyword research to identify the best
                  opportunities for your business. Our strategies focus on
                  high-traffic, low-competition keywords that drive relevant
                  traffic to your site.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  2. On-Page Optimization
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We optimize your website's content, meta tags, headers, and
                  images to ensure search engines understand your site's
                  relevance to user queries. Our on-page SEO techniques improve
                  your site's visibility and user experience.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  3. Technical SEO
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our technical SEO services include improving your site's
                  speed, mobile-friendliness, and overall performance. We also
                  ensure proper indexing and crawling by search engines.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  4. Link Building
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We build high-quality backlinks to improve your site's
                  authority and search engine rankings. Our link-building
                  strategies are ethical and focused on long-term results.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in SEO
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Google Analytics:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We use Google Analytics to track website performance, monitor
                  traffic sources, and understand user behavior, helping us make
                  data-driven decisions.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Ahrefs:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Ahrefs is a comprehensive tool for keyword research, backlink
                  analysis, and competitive research. It helps us identify
                  opportunities to improve your site's SEO.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/seo1.webp"
                  alt="SEO services"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="300">
                  SEMrush:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  SEMrush offers a suite of tools for keyword research, site
                  audit, and tracking your SEO progress. We use it to optimize
                  your site for better rankings and visibility.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Moz:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Moz provides tools for keyword research, link building, and
                  site auditing. We leverage Moz to ensure your site is
                  well-optimized and ranks well in search results.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="300">
                <h3>Why TechPulsar is the Best Choice for SEO</h3>
                <p>
                  TechPulsar delivers results-driven SEO services tailored to
                  your business needs. Our team combines deep industry knowledge
                  with innovative strategies to help you achieve and maintain
                  top search engine rankings.
                </p>
                <p>
                  We provide transparent reporting, personalized strategies, and
                  continuous optimization to ensure your online success. Our
                  commitment to excellence and client satisfaction makes us the
                  ideal partner for your SEO needs.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Expert SEO Team: </strong> Our team of SEO experts
                    has a proven track record of success in improving search
                    engine rankings and driving organic traffic.
                  </li>
                  <li>
                    <strong>Tailored Strategies: </strong> We customize our SEO
                    strategies to align with your business goals and target
                    audience, ensuring optimal results.
                  </li>
                  <li>
                    <strong>Comprehensive Services: </strong> From technical SEO
                    to content optimization, we offer a full suite of services
                    to improve your online visibility.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why SEO is Essential
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  SEO Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faqs />
    </section>
  );
}
