import React from "react";

export default function Hero() {
  return (
    <section id="hero" className="hero section accent-background">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1>Bettter Digital Experience With TechPulsar</h1>
            <p>
              We are a team of professional
              <span className="words words-1">
                <span>designers</span>
                <span>developers</span>
                <br />
              </span>
              crafting tailored web solutions for enhanced online visibility,
              engagement, and success.
            </p>
            <div className="d-flex">
              <a href="#about" className="btn-get-started">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <img
              src="assets/img/hero-img.png"
              className="img-fluid animated"
              alt="hero-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
