import React, { useEffect, useState } from "react";
import "./blogs.css";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch posts from WordPress REST API
    fetch(process.env.REACT_APP_WORDPRESS_BLOGS_LINK, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort blogs by date in descending order and take the latest 3
        const sortedBlogs = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBlogs(sortedBlogs.slice(0, 3));
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  return (
    <section id="blogs" className="blogs" data-aos="fade-up">
      <div className="container section-title">
        <h2>Latest Blogs Posts</h2>
        <p>Stay updated with our latest insights and stories</p>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          {blogs.map((blog) => (
            <div key={blog.id} className="col-lg-4 col-md-6 mb-4">
              <div className="blog-card">
                <div className="blog-image">
                  <img
                    src={
                      blog._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                      "default-image-url"
                    }
                    alt={blog.title.rendered}
                    className="img-fluid"
                  />
                </div>
                <div className="blog-body">
                  {/* Category badges */}
                  {blog._embedded?.["wp:term"]?.[0]?.[0]?.name && (
                    <span
                      className="blog-badge"
                      style={{ backgroundColor: "#6e3fd7" }} // Use a default color or handle tags
                    >
                      {/* You can add categories or tags here if available */}
                      {blog._embedded?.["wp:term"]?.[0]?.[0]?.name}
                    </span>
                  )}
                  {blog._embedded?.["wp:term"]?.[0]?.[1]?.name && (
                    <span
                      className="blog-badge"
                      style={{ backgroundColor: "#5846f9" }} // Use a default color or handle tags
                    >
                      {/* You can add categories or tags here if available */}
                      {blog._embedded?.["wp:term"]?.[0]?.[1]?.name}
                    </span>
                  )}
                  <h3 className="blog-title">{blog.title.rendered}</h3>
                  <p
                    className="blog-subtitle"
                    dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered }}
                  ></p>
                  <div className="blog-author">
                    <div className="author-info-container">
                      <img
                        src={
                          blog.authors[0]?.avatar_url?.url ||
                          "default-image-url"
                        }
                        alt="author avatar"
                        className="author-image"
                      />
                      <div className="author-info">
                        <p>
                          {blog.authors[0]?.display_name || "Default Author"}
                        </p>
                        <p className="text-muted">
                          {new Intl.DateTimeFormat("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "2-digit",
                          }).format(new Date(blog.date))}
                        </p>
                      </div>
                    </div>
                    <a
                      href={blog.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-read-more"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
