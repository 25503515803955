import React from "react";

export default function About() {
  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-6 order-1 order-lg-2"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="assets/img/about.jpg"
              className="img-fluid img-animated"
              alt="about-image"
            />
          </div>

          <div
            className="col-lg-6 order-2 order-lg-1 content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3>Empowering Your Business with Cutting-Edge Technology</h3>
            <p className="fst-italic">
              Dedicated to providing tailored services that meet the unique
              needs of businesses of all sizes—whether you're a startup, a
              growing medium-sized enterprise, or a large corporation.
            </p>
            <ul>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  We believe in honest and transparent communication, building
                  lasting relationships based on trust and reliability.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  We are passionate about leveraging the latest technologies to
                  deliver innovative solutions that address your challenges.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  Your success is our success. We are dedicated to ensuring that
                  our solutions have a positive and measurable impact on your
                  business.
                </span>
              </li>
            </ul>
            <a href="#whyus" className="read-more">
              <span>Why Us?</span>
              <i className="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
