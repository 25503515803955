import React from "react";

export default function TermsAndConditions() {
  return (
    <div className="container">
      <br />
      <h4 style={{ textDecoration: "underline" }}>Terms and Conditions</h4>
      <p style={{ padding: "5px" }}>
        <strong>Effective Date:</strong> 20<sup>th</sup> August 2024
      </p>

      <h5>1. Introduction</h5>
      <p>
        Welcome to Techpulsar! These Terms and Conditions ("Terms") govern your
        use of our website and services. By accessing or using Techpulsar's
        services, you agree to comply with and be bound by these Terms. If you
        do not agree with these Terms, please do not use our services.
        Techpulsar reserves the right to modify these Terms at any time, and
        such modifications will be effective immediately upon posting on our
        website. It is your responsibility to review these Terms periodically to
        be aware of any changes.
      </p>

      <h5>2. Quotations</h5>
      <p>
        Quotations provided by Techpulsar are valid for a period of 40 days from
        the date of issuance. After this period, the quotation may be revised to
        reflect current pricing and project requirements. Quotations are based
        on the information provided at the time of issuance. Any changes or
        additional requirements introduced after the quotation may result in an
        adjustment to the final price. Techpulsar will notify the client of any
        such adjustments and seek approval before proceeding with the work.
      </p>

      <h5>3. Project Timeline and Budget</h5>
      <p>
        The estimated project timeline provided in the quotation is based on the
        current scope of work. Any changes to the project scope, additional
        requirements, or unforeseen circumstances may affect the project
        timeline and budget. Techpulsar will make every effort to adhere to the
        agreed-upon timeline but cannot be held responsible for delays caused by
        factors beyond our control, including but not limited to client delays,
        technical issues, or third-party actions. If the project scope changes
        or additional requirements are introduced, Techpulsar will provide a
        revised timeline and budget for approval before proceeding.
      </p>

      <h5>4. Service Delivery</h5>
      <p>
        Techpulsar is committed to delivering services as outlined in the
        project agreement. We will make reasonable efforts to complete the
        project within the estimated timeline. However, certain factors may
        impact the delivery of services, including changes in project scope,
        client feedback, and external factors. In case of any anticipated
        delays, Techpulsar will promptly communicate with the client and provide
        updated delivery schedules. The client acknowledges that Techpulsar is
        not liable for any damages or losses resulting from delays or
        interruptions caused by factors beyond our control.
      </p>

      <h5>5. Payment Terms</h5>
      <p>
        Payment terms will be specified in the project agreement. Typically,
        payments are structured as follows:
      </p>
      <ul>
        <li>
          <strong>Initial Deposit:</strong> An initial deposit may be required
          before the commencement of work. The amount and due date of the
          deposit will be specified in the project agreement.
        </li>
        <li>
          <strong>Milestone Payments:</strong> Payments may be due at various
          stages of the project, as outlined in the agreement. Milestone
          payments are based on the completion of specific project phases or
          deliverables.
        </li>
        <li>
          <strong>Final Payment:</strong> The final payment is due upon project
          completion and delivery. The final payment amount will be specified in
          the project agreement and is payable before the project is considered
          complete.
        </li>
      </ul>
      <p>
        All payments should be made according to the terms specified in the
        agreement. Late payments may incur additional charges or affect the
        project timeline. Techpulsar reserves the right to suspend work or
        terminate the agreement if payments are not made as agreed.
      </p>

      <h5>6. Confidentiality</h5>
      <p>
        Techpulsar values your privacy and is committed to protecting your
        confidential information. Any sensitive or proprietary information
        shared with Techpulsar will be used solely for the purpose of completing
        the project and will not be disclosed to third parties without your
        explicit consent. Techpulsar will implement reasonable security measures
        to protect your information from unauthorized access, disclosure, or
        use. However, please be aware that no method of transmission over the
        internet or electronic storage is completely secure, and we cannot
        guarantee absolute security.
      </p>

      <h5>7. Intellectual Property</h5>
      <p>
        Upon full payment, you will have ownership of the deliverables created
        for your project. This includes all work product, designs, and other
        materials created as part of the project. Techpulsar retains the right
        to showcase the project in our portfolio and marketing materials unless
        otherwise agreed upon. Intellectual property rights related to the
        tools, technologies, or frameworks used during the project remain with
        their respective owners. Techpulsar grants you a non-exclusive,
        non-transferable license to use the deliverables for the purposes
        specified in the project agreement.
      </p>

      <h5>8. Limitation of Liability</h5>
      <p>
        Techpulsar is not liable for any indirect, incidental, or consequential
        damages arising from the use or inability to use our services. This
        includes, but is not limited to, damages for loss of profits, data, or
        other intangible losses. Our liability for any direct damages is limited
        to the amount paid for the services rendered, as specified in the
        project agreement. Techpulsar is not responsible for any damages or
        losses incurred due to third-party actions, technical issues, or other
        factors beyond our control.
      </p>

      <h5>9. Termination</h5>
      <p>
        Either party may terminate the project agreement with written notice if
        the other party fails to comply with the terms of the agreement or if
        there are significant changes in the project's scope or requirements. In
        the event of termination, the client will be billed for the work
        completed up to the termination date. Techpulsar will deliver all
        completed work, and any outstanding payments must be settled before the
        project can be concluded. Termination of the agreement does not affect
        any rights or obligations that have accrued prior to termination.
      </p>

      <h5>10. Changes to Terms</h5>
      <p>
        Techpulsar reserves the right to update or modify these Terms and
        Conditions at any time. Any changes will be communicated through our
        website or directly to clients. Your continued use of our services after
        changes have been made constitutes acceptance of the updated terms. It
        is your responsibility to review these Terms periodically to be aware of
        any changes.
      </p>

      <h5>11. Governing Law</h5>
      <p>
        These Terms and Conditions are governed by and construed in accordance
        with the laws of the jurisdiction in which Techpulsar operates. Any
        disputes arising from these Terms shall be resolved in the competent
        courts of that jurisdiction. The parties agree to submit to the
        exclusive jurisdiction of the courts in that jurisdiction.
      </p>

      <h5>12. Dispute Resolution</h5>
      <p>
        In the event of a dispute or claim arising from or related to these
        Terms or the services provided, the parties agree to first seek
        resolution through informal negotiations. If a resolution cannot be
        reached through informal means, the dispute may be submitted to
        mediation or arbitration, as agreed upon by both parties. The costs
        associated with mediation or arbitration will be borne equally by both
        parties.
      </p>

      <h5>13. Force Majeure</h5>
      <p>
        Techpulsar will not be held liable for any failure or delay in
        performing its obligations under these Terms if such failure or delay is
        due to a force majeure event. Force majeure events include, but are not
        limited to, natural disasters, war, terrorism, labor disputes, and other
        events beyond Techpulsar's reasonable control. In the event of a force
        majeure, Techpulsar will make reasonable efforts to resume performance
        as soon as possible.
      </p>

      <h5>14. Indemnification</h5>
      <p>
        You agree to indemnify, defend, and hold harmless Techpulsar, its
        officers, directors, employees, and agents from and against any claims,
        liabilities, damages, losses, and expenses (including reasonable
        attorneys' fees) arising out of or related to your use of our services,
        violation of these Terms, or infringement of any intellectual property
        or other rights of any third party.
      </p>

      <h5>15. Entire Agreement</h5>
      <p>
        These Terms and Conditions constitute the entire agreement between you
        and Techpulsar regarding the use of our services and supersede all prior
        or contemporaneous agreements and understandings, whether written or
        oral, relating to the subject matter of these Terms. Any amendments or
        modifications to these Terms must be made in writing and signed by both
        parties.
      </p>

      <h5>16. Contact Information</h5>
      <p>
        If you have any questions or concerns regarding these Terms and
        Conditions, please contact us at:
      </p>
      <p>
        <strong>Techpulsar</strong>
        <br />
        Email: info@techpulsar.com
        <br />
      </p>

      <h5>17. Severability</h5>
      <p>
        If any provision of these Terms and Conditions is found to be invalid or
        unenforceable by a court of competent jurisdiction, the remaining
        provisions will continue in full force and effect. The invalid or
        unenforceable provision will be deemed modified to the extent necessary
        to make it valid and enforceable, consistent with the original intent of
        the parties.
      </p>

      <h5>18. Waiver</h5>
      <p>
        No waiver of any term or condition of these Terms will be deemed a
        further or continuing waiver of such term or condition or any other term
        or condition. Any failure by Techpulsar to enforce any provision of
        these Terms will not be deemed a waiver of future enforcement of that or
        any other provision.
      </p>

      <h5>19. Assignment</h5>
      <p>
        Techpulsar may assign or transfer its rights and obligations under these
        Terms to any third party without restriction. You may not assign or
        transfer any rights or obligations under these Terms without the prior
        written consent of Techpulsar. Any attempted assignment or transfer in
        violation of this provision will be null and void.
      </p>

      <h5>20. Use of Services</h5>
      <p>
        You agree to use Techpulsar's services only for lawful purposes and in
        accordance with these Terms. You are solely responsible for ensuring
        that your use of the services complies with all applicable laws and
        regulations. Any misuse of the services or violation of these Terms may
        result in the suspension or termination of your access to the services.
      </p>

      <h5>21. Feedback and Suggestions</h5>
      <p>
        We welcome and encourage feedback and suggestions about our services.
        Any feedback or suggestions provided to Techpulsar will become our
        property, and we will have the right to use, reproduce, and disclose
        such feedback and suggestions without any compensation or obligation to
        you.
      </p>

      <h5>22. Third-Party Links</h5>
      <p>
        Our website may contain links to third-party websites that are not owned
        or controlled by Techpulsar. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party websites. By using our services, you acknowledge and agree
        that Techpulsar is not responsible for any damages or losses arising
        from your use of any third-party websites.
      </p>

      <h5>23. User Account</h5>
      <p>
        If you create an account with Techpulsar, you are responsible for
        maintaining the confidentiality of your account credentials and for all
        activities that occur under your account. You agree to notify Techpulsar
        immediately of any unauthorized use of your account or any other breach
        of security. Techpulsar will not be liable for any loss or damage
        arising from your failure to protect your account information.
      </p>

      <h5>24. Dispute Resolution</h5>
      <p>
        In the event of any disputes or claims arising from or relating to these
        Terms, the parties agree to first attempt to resolve the dispute through
        good-faith negotiations. If the dispute cannot be resolved through
        negotiation, the parties may seek resolution through mediation or
        arbitration, as agreed upon. The costs of mediation or arbitration will
        be shared equally by the parties.
      </p>

      {/* <h5>25. International Use</h5>
      <p>
        Techpulsar’s services are intended for use by residents of . If you
        access our services from outside, you do so at your own risk and are
        responsible for complying with local laws and regulations. Techpulsar
        makes no representation that the services are appropriate or available
        for use in other locations.
      </p>

      <h5>26. Governing Law</h5>
      <p>
        These Terms and Conditions are governed by and construed in accordance
        with the laws of [Insert Jurisdiction]. Any legal action or proceeding
        arising under or related to these Terms shall be brought exclusively in
        the courts located in [Insert Jurisdiction], and you consent to the
        personal jurisdiction and venue of such courts.
      </p> */}

      <h5>27. Miscellaneous</h5>
      <p>
        These Terms and Conditions constitute the entire agreement between you
        and Techpulsar regarding your use of our services and supersede all
        prior agreements or understandings, whether written or oral, related to
        the subject matter. The headings in these Terms are for convenience only
        and have no legal effect. Any provision of these Terms that is held to
        be invalid or unenforceable will be interpreted in a manner that most
        closely resembles the original intent of the provision, and the
        remaining provisions will continue in full force and effect.
      </p>

      <h5>28. Electronic Communications</h5>
      <p>
        By using Techpulsar’s services, you consent to receive electronic
        communications from us. These communications may include notices about
        your account, updates about our services, and other information related
        to your use of our services. You agree that all agreements, notices,
        disclosures, and other communications we provide to you electronically
        satisfy any legal requirement that such communications be in writing.
      </p>
    </div>
  );
}
