import React from "react";

const ServiceCard = ({ iconClass, title, description, link }) => {
  return (
    <div className="col-xl-3 col-lg-3" data-aos="fade-up" data-aos-delay="100">
      <div className="single__box service-item d-flex">
        <div className="box__icon">
          <i className={iconClass}></i>
        </div>
        <h4 className="box__title">{title}</h4>
        <div className="box__description">
          <center>
            <p className="description">
              {description}{" "}
              <a
                href={link}
                className="box__description"
                style={{ textDecoration: "underline", fontStyle: "italic" }}
              >
                Learn More
              </a>
            </p>
          </center>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
