import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const [activeSection, setActiveSection] = useState("hero");
  const location = useLocation();

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
    document.body.classList.toggle("mobile-nav-active", !isMobileNavActive);
  };

  const closeMobileNav = () => {
    setIsMobileNavActive(false);
    document.body.classList.remove("mobile-nav-active");
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll("section[id]");
    const scrollY = window.scrollY;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 100; // Adjust for your header height
      const sectionHeight = section.offsetHeight;
      const sectionId = section.getAttribute("id");

      if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
        setActiveSection(sectionId);
      }
    });
  };

  const handleHashChange = () => {
    const hash = location.hash.replace("#", "");
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        setActiveSection(hash);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    handleHashChange();
  }, [location]);

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <h1 className="sitename">TechPulsar</h1>
        </a>

        <nav
          id="navmenu"
          className={`navmenu ${isMobileNavActive ? "mobile-nav-active" : ""}`}
        >
          <ul>
            <li>
              <a
                href="/#hero"
                className={activeSection === "hero" ? "active" : ""}
                onClick={closeMobileNav}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/#about"
                className={activeSection === "about" ? "active" : ""}
                onClick={closeMobileNav}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/#services"
                className={activeSection === "services" ? "active" : ""}
                onClick={closeMobileNav}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="/#portfolio"
                className={activeSection === "portfolio" ? "active" : ""}
                onClick={closeMobileNav}
              >
                Work
              </a>
            </li>
            <li>
              <a
                href="/#blogs"
                className={activeSection === "blogs" ? "active" : ""}
                onClick={closeMobileNav}
              >
                Blogs
              </a>
            </li>
            <li>
              <a
                href="/#contact"
                className={activeSection === "contact" ? "active" : ""}
                onClick={closeMobileNav}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href="https://tools.techpulsar.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tools &nbsp;
                <i class="fa-solid fa-square-arrow-up-right icon-size"></i>              </a>
            </li>
          </ul>
          <i
            className={`mobile-nav-toggle d-xl-none bi ${
              isMobileNavActive ? "bi-x" : "bi-list"
            }`}
            onClick={toggleMobileNav}
          ></i>
        </nav>

        <a className="btn-getstarted" href="/get-quote">
          Get A Quote
        </a>
      </div>
    </header>
  );
}
