import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";

export default function DigitalMarketingPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash);
    }
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>Digital Marketing | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar offers comprehensive digital marketing services to boost your online presence, increase traffic, and drive conversions with tailored strategies."
        />
        <meta
          name="keywords"
          content="digital marketing, online marketing, SEO, SEM, social media marketing, content marketing, PPC"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://techpulsar.com/digital-marketing" />

        {/* <!-- Open Graph Tags --> */}
        <meta property="og:title" content="Digital Marketing | TechPulsar" />
        <meta
          property="og:description"
          content="Enhance your online visibility with TechPulsar's digital marketing services. From SEO to social media marketing, we provide strategies to grow your business."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/digital-marketing.jpg"
        />
        <meta
          property="og:url"
          content="https://techpulsar.com/digital-marketing"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          <h1>Digital Marketing</h1>
          <p>
            Boost Your Online Presence with Our Comprehensive Digital Marketing
            Services
          </p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / Digital Marketing</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to Digital Marketing Services</h3>
                <p>
                  At TechPulsar, we offer comprehensive digital marketing
                  services designed to increase your online visibility, drive
                  targeted traffic to your website, and help you achieve your
                  business goals.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/digital-marketing.jpg"
                alt="Digital Marketing services"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: "3%",
                }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why Digital Marketing is Essential for Your Business</h3>
                <p>
                  In today’s digital age, a strong online presence is crucial
                  for any business. Digital marketing provides the tools and
                  strategies needed to reach your target audience, convert leads
                  into customers, and grow your brand’s visibility.
                </p>
                <p>
                  Whether you’re a small business or a large enterprise,
                  TechPulsar’s digital marketing services can help you navigate
                  the complex digital landscape and achieve measurable results.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Digital Marketing Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h4>1. Search Engine Optimization (SEO)</h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our SEO services are designed to improve your website’s
                  visibility on search engines, helping you attract more organic
                  traffic and rank higher for relevant keywords.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  2. Pay-Per-Click (PPC) Advertising
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We manage effective PPC campaigns that drive targeted traffic
                  to your website, maximize ROI, and ensure that your ads reach
                  the right audience.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  3. Social Media Marketing
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Engage with your audience on popular social media platforms.
                  We create and manage social media campaigns that build your
                  brand, increase followers, and drive traffic to your site.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  4. Content Marketing
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We develop and distribute valuable, relevant content that
                  attracts and engages your target audience, driving profitable
                  customer action.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  5. Email Marketing
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our email marketing services help you stay connected with your
                  customers, promote your products or services, and drive
                  conversions through targeted email campaigns.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in Digital Marketing
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Google Analytics:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We use Google Analytics to track website performance, monitor
                  user behavior, and gain insights into how visitors interact
                  with your site.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  SEMrush & Ahrefs:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  These powerful SEO tools help us conduct keyword research,
                  analyze competitors, and optimize your website for better
                  search engine rankings.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/digital-marketing1.jpg"
                  alt="Digital Marketing tools"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="300">
                  HubSpot & Mailchimp:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  For email marketing, we rely on HubSpot and Mailchimp to
                  create, manage, and analyze campaigns that convert leads into
                  customers.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Hootsuite & Buffer:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We use Hootsuite and Buffer to manage and schedule social
                  media posts across multiple platforms, ensuring consistent
                  engagement with your audience.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="300">
                <h3>Why TechPulsar is the Best Choice for Digital Marketing</h3>
                <p>
                  TechPulsar's digital marketing services are tailored to meet
                  the unique needs of your business. We use data-driven
                  strategies to create campaigns that deliver results, helping
                  you achieve your marketing goals.
                </p>
                <p>
                  Our team of experienced digital marketers, combined with our
                  focus on innovation and customer satisfaction, makes
                  TechPulsar the ideal partner for your digital marketing needs.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Data-Driven Approach: </strong>We use data and
                    analytics to inform our strategies, ensuring that your
                    digital marketing campaigns are effective and efficient.
                  </li>
                  <li>
                    <strong>Comprehensive Services: </strong>From SEO to social
                    media, we offer a full range of digital marketing services
                    designed to meet your business objectives.
                  </li>
                  <li>
                    <strong>Results-Oriented: </strong>Our primary focus is on
                    delivering measurable results that drive growth and success
                    for your business.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why Digital Marketing is Essential
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  Digital Marketing Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faqs />
    </section>
  );
}
