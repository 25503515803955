import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";
// import StarBackground from "../components/stars/starBackground";

export default function UiuxPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    // Set the initial active tab based on the URL hash
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash); // Scroll to the section initially if there's a hash
    }
  }, []);

  useEffect(() => {
    // Update the active tab when the URL hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    // Smooth scrolling to the section
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // Delay setting activeTab to ensure scrolling completes
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>UI UX Design | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar offers expert UI/UX design services to create user-friendly and visually appealing digital experiences that drive engagement and success."
        />
        <meta
          name="keywords"
          content="UI/UX design, user experience design, user interface design, website design, app design"
        />
        <meta property="og:title" content="UI UX Design | TechPulsar" />
        <meta
          property="og:description"
          content="TechPulsar offers expert UI/UX design services to create user-friendly and visually appealing digital experiences that drive engagement and success."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/uiux1.webp"
        />{" "}
        {/* Replace with your actual image URL */}
        <meta property="og:url" content="https://techpulsar.com/uiux-design" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://techpulsar.com/uiux-design" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          {/* <StarBackground /> */}
          <h1>UI/UX Design</h1>
          <p>Designing Seamless and Visually Stunning User Experiences</p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / UI UX Design</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to UI/UX Design Services</h3>
                <p>
                  Elevate your digital presence with TechPulsar's premier UI/UX
                  design services. Our dedicated team specializes in crafting
                  user-centric designs that not only enhance user engagement but
                  also streamline navigation and drive success across all
                  digital platforms. Whether you're developing a new app or
                  redesigning your website, our UI/UX solutions are tailored to
                  meet your unique needs and goals.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/uiux1.webp"
                alt="UI/UX solutions"
                style={{ width: "100%", borderRadius: 10, marginBottom: "3%" }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why UI/UX Design is Essential</h3>
                <p>
                  In today’s highly competitive digital landscape, exceptional
                  UI/UX design is more than just a luxury—it’s a necessity.
                  Effective UI/UX design plays a crucial role in retaining
                  users, converting visitors into customers, and ensuring that
                  your digital presence is both engaging and efficient.
                </p>
                <p>
                  Research highlights the importance of design in user
                  perception, with 75% of users judging a company's credibility
                  based on its website design. A well-crafted UI/UX not only
                  enhances user satisfaction but also significantly contributes
                  to your business’s bottom line. Investing in high-quality
                  UI/UX design translates to better user experiences, higher
                  conversion rates, and a more compelling online presence.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Detailed Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>1. User Research and Personas</h4>
                <p>
                  Understanding your audience is the cornerstone of effective
                  design. At TechPulsar, we start by conducting comprehensive
                  user research to craft detailed personas. These personas guide
                  every design decision, ensuring that our solutions are
                  user-centric and tailored to meet the specific needs and
                  behaviors of your target audience.
                </p>
              </div>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>2. Wireframing and Prototyping</h4>
                <p>
                  Our wireframes and prototypes offer a visual blueprint of your
                  project. This allows you to see the user journey and refine
                  your ideas before moving into full-scale development.
                  Wireframing helps in mapping out the structure and
                  functionality of your design, while prototyping provides an
                  interactive model for testing and feedback.
                </p>
              </div>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>3. Visual Design</h4>
                <p>
                  We combine aesthetics with functionality to create visually
                  stunning designs that are also highly intuitive. Our approach
                  ensures that your design is not only attractive but also
                  enhances user interaction, making your website or app both
                  engaging and easy to use.
                </p>
              </div>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>4. Usability Testing</h4>
                <p>
                  Usability testing is a crucial part of our design process. We
                  conduct rigorous tests to ensure that your designs meet the
                  needs of your target users, optimizing for both efficiency and
                  satisfaction. This helps in identifying and resolving any
                  issues before your design goes live, ensuring a seamless user
                  experience.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in UI/UX Design
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  Figma:
                </h4>
                <p data-aos="fade-up" data-aos-delay="200">
                  Figma is a collaborative design tool that enables our team to
                  work together in real-time, ensuring a cohesive and
                  streamlined design process. Its cloud-based nature allows for
                  easy sharing and feedback, facilitating effective
                  collaboration.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  Adobe XD:
                </h4>
                <p data-aos="fade-up" data-aos-delay="200">
                  We use Adobe XD to create detailed and interactive prototypes,
                  allowing you to visualize your project before development.
                  Adobe XD’s powerful features enable us to design and test
                  interactive elements, ensuring that your design performs as
                  intended.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/uiux2.webp"
                  alt="UI/UX solutions"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="200">
                  Sketch:
                </h4>
                <p data-aos="fade-up" data-aos-delay="200">
                  An industry-standard tool for vector-based designs, Sketch is
                  perfect for creating high-quality and scalable UI designs. Its
                  intuitive interface and robust features make it ideal for
                  responsive design, ensuring that your design looks great on
                  any device.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  InVision:
                </h4>
                <p data-aos="fade-up" data-aos-delay="200">
                  InVision enhances our design collaboration and feedback loops,
                  ensuring that every stakeholder is aligned. It allows for
                  seamless sharing of prototypes and gathering of feedback,
                  making the design process more efficient and transparent.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="200">
                <h3>Why TechPulsar is the Best Choice</h3>
                <p>
                  At TechPulsar, we take pride in delivering UI/UX design
                  services that exceed client expectations. Our experienced
                  team, combined with cutting-edge tools and methodologies,
                  ensures that your project is in the best hands.
                </p>
                <p>
                  We offer personalized service, fast turnaround times, and
                  innovative solutions tailored to your business needs. Our
                  portfolio speaks for itself, with successful projects across
                  various industries, driving real results for our clients.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Experienced Designers: </strong> Our team has years
                    of experience in creating user-centric designs that drive
                    results.
                  </li>
                  <li>
                    <strong>Custom Solutions: </strong> We tailor our services
                    to meet the specific needs of your business and audience.
                  </li>
                  <li>
                    <strong>Cutting-Edge Tools: </strong> We utilize the latest
                    design tools and trends to ensure your product stands out in
                    the market.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why UI/UX Design is Essential
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>At TechPulsar,</h4>
                <p>
                  We provide a wide range of software development services
                  designed to elevate your business. From crafting visually
                  stunning websites and user-friendly apps to optimizing your
                  online presence through SEO and digital marketing, we ensure
                  every solution is tailored to your unique needs. Our services
                  also include cloud management, security testing, and
                  continuous integration, ensuring your digital assets are
                  secure and your projects are delivered efficiently.
                </p>
              </div>
              <Faqs />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
