import React from "react";
import Hero from "../sections/hero";
import About from "../sections/about";
import ServicesSection from "../sections/services";
import Stats from "../sections/stats";
import WhyUs from "../sections/whyUs";
import Work from "../sections/work";
import Faqs from "../sections/faqs";
import ContactCard from "../sections/ContactCard";
import Blogs from "../sections/blogs/Blogs";
// import Contact from "../sections/Contact";

export default function HomePage() {
  return (
    <>
      <Hero />
      <About />
      <ServicesSection />
      <Stats />
      <WhyUs />
      <Work />
      <Faqs />
      <Blogs />
      <ContactCard />
      {/* <Contact /> */}
    </>
  );
}
