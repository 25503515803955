import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";

export default function AppDevelopmentPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash);
    }
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>App Development | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar specializes in app development services to create high-quality, scalable, and user-friendly mobile and web applications tailored to your business needs."
        />
        <meta
          name="keywords"
          content="app development, mobile app development, web app development, custom app development, iOS app development, Android app development"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://techpulsar.com/app-development" />

        {/* <!-- Open Graph Tags --> */}
        <meta
          property="og:title"
          content="App Development Services | TechPulsar"
        />
        <meta
          property="og:description"
          content="Create innovative and high-performance applications with TechPulsar's app development services. We offer tailored solutions for mobile and web platforms."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/appdev.webp"
        />
        <meta
          property="og:url"
          content="https://techpulsar.com/app-development"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          <h1>App Development</h1>
          <p>Creating Innovative Mobile Applications for Your Business</p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / App Development</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to App Development Services</h3>
                <p>
                  At TechPulsar, we develop mobile applications that empower
                  businesses to engage with their customers on the go. Our app
                  development services are designed to create user-friendly,
                  feature-rich mobile apps that deliver an exceptional user
                  experience.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/appdev.webp"
                alt="App Development services"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: "3%",
                }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why App Development is Crucial for Your Business</h3>
                <p>
                  Mobile applications are an essential tool for businesses
                  looking to stay connected with their customers. A
                  well-designed app can enhance customer engagement, improve
                  brand loyalty, and drive revenue growth.
                </p>
                <p>
                  With the increasing reliance on smartphones, having a mobile
                  app for your business is no longer optional. TechPulsar's app
                  development services ensure that your app stands out in a
                  crowded market.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Detailed App Development Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h4>1. iOS App Development</h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We develop high-quality iOS apps tailored to meet the needs of
                  your business and its users. Our team follows the latest
                  guidelines and uses the best tools to create apps that are
                  intuitive and easy to use.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  2. Android App Development
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our Android app development services cover everything from
                  concept to deployment. We build Android apps that are
                  responsive, secure, and optimized for performance on all
                  devices.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  3. Cross-Platform App Development
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We create cross-platform apps that run smoothly on both iOS
                  and Android devices. Our cross-platform solutions help you
                  reach a broader audience while maintaining a consistent user
                  experience across platforms.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  4. App Maintenance & Support
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Beyond development, we offer app maintenance and support
                  services to keep your app running smoothly. We handle updates,
                  bug fixes, and enhancements to ensure your app remains
                  relevant and functional.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in App Development
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Swift & Objective-C:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  These programming languages are the foundation of our iOS app
                  development. We use them to build fast, reliable, and
                  user-friendly iOS applications.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Kotlin & Java:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  For Android app development, we leverage Kotlin and Java to
                  create apps that are efficient, secure, and compatible with a
                  wide range of Android devices.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/appdev1.jpg"
                  alt="App Development tools"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="300">
                  Flutter & React Native:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We use Flutter and React Native to build cross-platform
                  applications that provide a native-like experience on both iOS
                  and Android devices.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Xcode & Android Studio:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our developers use Xcode and Android Studio as the primary
                  IDEs for app development. These powerful tools enable us to
                  build, test, and deploy high-quality apps efficiently.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="300">
                <h3>Why TechPulsar is the Best Choice for App Development</h3>
                <p>
                  TechPulsar's app development services are backed by years of
                  experience and a commitment to quality. We deliver apps that
                  not only meet your business requirements but also exceed user
                  expectations.
                </p>
                <p>
                  Our team of expert developers, combined with our focus on
                  innovation and customer satisfaction, makes TechPulsar the
                  ideal partner for your app development project.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Expertise Across Platforms: </strong>We have
                    extensive experience in developing apps for both iOS and
                    Android platforms, ensuring that your app reaches the widest
                    possible audience.
                  </li>
                  <li>
                    <strong>Innovative Solutions: </strong>We use the latest
                    technologies and development practices to create apps that
                    are not only functional but also innovative and engaging.
                  </li>
                  <li>
                    <strong>Dedicated Support: </strong>Our team is dedicated to
                    providing ongoing support and maintenance to keep your app
                    performing at its best.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why App Development is Crucial
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  App Development Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faqs />
    </section>
  );
}
