import React from "react";

export default function WhyUs() {
  return (
    <section id="whyus" className="features section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Why Choose Us?</h2>
        <p>Why TechPulsar? Excellence, Innovation, and Reliability</p>
      </div>

      <div className="container">
        <div className="row gy-4 justify-content-between">
          <div
            className="features-image col-lg-5 order-lg-2 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src="assets/img/features.svg" className="img-fluid img-animated" alt="features-image" />
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div
              className="features-item d-flex ps-0 ps-lg-3 pt-4 pt-lg-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <i className="bi bi-award flex-shrink-0"></i>
              <div>
                <h4>Expert Team</h4>
                <p>
                  Our skilled professionals bring years of industry experience
                  and technical knowledge to every project, ensuring
                  high-quality results.
                </p>
              </div>
            </div>

            <div
              className="features-item d-flex mt-5 ps-0 ps-lg-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <i className="bi bi-bar-chart-steps flex-shrink-0"></i>
              <div>
                <h4>Client-Centric Approach</h4>
                <p>
                  We prioritize your needs and work closely with you to deliver
                  solutions that are tailored to your specific requirements.
                </p>
              </div>
            </div>

            <div
              className="features-item d-flex mt-5 ps-0 ps-lg-3"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i className="bi bi-broadcast flex-shrink-0"></i>
              <div>
                <h4>Innovation & Excellence</h4>
                <p>
                  We stay abreast of the latest technological advancements to
                  provide you with state-of-the-art solutions that drive
                  efficiency and growth.{" "}
                </p>
              </div>
            </div>

            <div
              className="features-item d-flex mt-5 ps-0 ps-lg-3"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <i className="bi bi-list-stars flex-shrink-0"></i>
              <div>
                <h4>End-to-End Services</h4>
                <p>
                  From initial consultation to ongoing support, we offer a
                  comprehensive range of services to cover all your IT and
                  software needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
