import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <br />
      <h4 style={{ textDecoration: "underline" }}>Privacy Policy</h4>
      <p style={{ padding: "5px" }}>
        <strong>Effective Date:</strong> 20<sup>th</sup> August 2024
      </p>

      <h5>1. Introduction</h5>
      <p>
        Welcome to Techpulsar! At Techpulsar (accessible at{" "}
        <a href="https://techpulsar.com">techpulsar.com</a>), we are committed
        to protecting your privacy. This Privacy Policy outlines the types of
        information we collect from our users and clients, how we use and
        protect that information, and your rights regarding your personal data.
      </p>

      <h5>2. Information We Collect</h5>
      <p>
        When you interact with our website or engage our services, we may
        collect the following types of information:
      </p>
      <ul>
        <li>
          <strong>Basic Contact Information:</strong> Name, email address, phone
          number (primarily collected through our contact form).
        </li>
        <li>
          <strong>Project-Specific Information:</strong> If you engage us for IT
          and software development services, we may require additional personal
          information, including tax details, specific certificates,
          identification documents, or other details relevant to your project.
        </li>
      </ul>

      <h5>3. How We Use Your Information</h5>
      <p>We use the information collected for the following purposes:</p>
      <ul>
        <li>
          <strong>To Provide Services:</strong> Your contact information helps
          us respond to inquiries, offer our services, and communicate with you
          about your project needs.
        </li>
        <li>
          <strong>For Project Development:</strong> If additional personal
          information is required for a specific project, it will only be used
          to fulfill the requirements of that project.
        </li>
        <li>
          <strong>Business Portfolio:</strong> Your project details may be
          showcased in our portfolio with your consent.
        </li>
      </ul>

      <h5>4. Sharing Your Information</h5>
      <p>
        At Techpulsar, we respect your privacy. We do not share your personal
        information with third parties, except as necessary to complete project
        development, and always with your explicit consent.
      </p>

      <h5>5. Security of Your Information</h5>
      <p>
        We take reasonable security measures to protect your personal
        information. Although our website is static and mainly showcases our
        services, any information you submit through our contact form is handled
        with care and protected against unauthorized access or disclosure.
      </p>

      <h5>6. Your Rights</h5>
      <p>You have the right to:</p>
      <ul>
        <li>
          <strong>Access:</strong> Request access to the personal information we
          hold about you.
        </li>
        <li>
          <strong>Correction:</strong> Request corrections to any inaccuracies
          in your information.
        </li>
        <li>
          <strong>Deletion:</strong> Request the deletion of your personal
          information, subject to any legal obligations we may have.
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at [insert contact
        email].
      </p>

      <h5>7. Changes to This Privacy Policy</h5>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for legal reasons. We encourage you to review this
        page periodically for the latest information.
      </p>

      <h5>8. Contact Us</h5>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        <strong className="box__title">Techpulsar</strong>
        <br />
        Email: info@techpulsar.com
        <br />
      </p>
    </div>
  );
}
