import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./GetQuote.css"; // Your existing CSS

const GetQuote = () => {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_API_ID
      )
      .then(
        () => {
          setIsLoading(false);
          toast.success("Your quote request has been sent successfully!", {
            position: "top-left",
          });
        },
        (error) => {
          setIsLoading(false);
          toast.error("Failed to send the request. Please try again.", {
            position: "top-left",
          });
          console.error("FAILED...", error.text);
        }
      );
  };

  return (
    <section className="get-quote-area ptb-100" id="get-a-quote">
      <div className="container">
        <div className="section-title">
          <h2>Get a Free Quote</h2>
          <p>
            Receive a personalized estimate for your business or personal needs.
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 order-last order-lg-first">
            <div className="quote-form">
              <h3>Get a Quote Today</h3>
              <p>
                Fill out this form, and we will contact you with a personalized
                quote.
              </p>
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="user_name"
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    name="user_phone"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="business_type"
                    required
                  >
                    <option value="">Select Your Business Type</option>
                    <option value="E-commerce">E-commerce</option>
                    <option value="Retail">Retail</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Finance">Finance</option>
                    <option value="Technology">Technology</option>
                    <option value="Education">Education</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <select className="form-control" name="service_type" required>
                    <option value="">Select Service Type</option>
                    <option value="Web Development">Web Development</option>
                    <option value="App Development">App Development</option>
                    <option value="UI/UX Design">UI/UX Design</option>
                    <option value="SEO">SEO</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="WordPress Development">
                      WordPress Development
                    </option>
                    <option value="Other Services">Other Services</option>
                  </select>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Tell us more about your needs..."
                    required
                    style={{ height: "100px" }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="default-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    "Submit a Quote"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-first order-lg-last">
            <div className="get-quote-img">
              <img
                src="assets/img/services/quote.webp"
                alt="Get a Quote"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default GetQuote;
