import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";

export default function WebDevelopmentPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash);
    }
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>Web Development | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar offers top-notch web development services to build dynamic, responsive, and high-performance websites tailored to your business needs."
        />
        <meta
          name="keywords"
          content="web development, website development, responsive web design, custom web development, front-end development, back-end development"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://techpulsar.com/website-development"
        />

        {/* <!-- Open Graph Tags --> */}
        <meta
          property="og:title"
          content="Web Development Services | TechPulsar"
        />
        <meta
          property="og:description"
          content="Build dynamic and responsive websites with TechPulsar's web development services. We offer custom solutions tailored to your business requirements."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/webdev.webp"
        />
        <meta
          property="og:url"
          content="https://techpulsar.com/website-development"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          <h1>Web Development</h1>
          <p>Building Robust and Scalable Websites for Your Business</p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / Web Development</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to Web Development Services</h3>
                <p>
                  At TechPulsar, we specialize in creating high-performance,
                  user-friendly websites that drive engagement and deliver
                  results. Our web development services are tailored to your
                  business needs, ensuring a strong online presence and seamless
                  user experience.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/webdev.webp"
                alt="Web Development services"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: "3%",
                }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why Web Development is Essential</h3>
                <p>
                  In today's digital landscape, a strong online presence is
                  critical for business success. A well-developed website not
                  only attracts visitors but also converts them into loyal
                  customers. Our web development services ensure that your site
                  is optimized for performance, security, and scalability.
                </p>
                <p>
                  With the majority of customers turning to the internet to find
                  products and services, having a website that stands out is
                  essential. TechPulsar's web development services are designed
                  to help you stay ahead of the competition.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Detailed Web Development Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h4>1. Custom Website Development</h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We build custom websites from scratch, ensuring that your site
                  is tailored to your brand and business goals. Our team uses
                  the latest technologies to create websites that are both
                  visually appealing and highly functional.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  2. Responsive Web Design
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We ensure your website looks great and functions well on all
                  devices, from desktops to smartphones. Our responsive web
                  design services guarantee a seamless experience for all users.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  3. E-commerce Development
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our e-commerce development services include creating secure
                  and user-friendly online stores. We integrate payment
                  gateways, inventory management systems, and other essential
                  features to provide a complete e-commerce solution.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  4. CMS Development
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We develop content management systems (CMS) that allow you to
                  easily update your website's content. Whether you need a
                  custom CMS or a platform like WordPress, we provide solutions
                  that make managing your site simple.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in Web Development
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  HTML, CSS, & JavaScript:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  These core technologies form the backbone of our web
                  development process, allowing us to create responsive,
                  interactive, and fast-loading websites.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  WordPress:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  WordPress is our go-to CMS for creating websites that are easy
                  to manage and customize. We use WordPress to build everything
                  from simple blogs to complex e-commerce sites.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/webdev1.jpg"
                  alt="Web Development tools"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="300">
                  Git & GitHub:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We use Git for version control, ensuring that our development
                  process is organized and collaborative. GitHub is our platform
                  of choice for managing and sharing code.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Visual Studio Code:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our developers use Visual Studio Code as their primary code
                  editor, taking advantage of its powerful features and
                  extensions to write clean and efficient code.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="300">
                <h3>Why TechPulsar is the Best Choice for Web Development</h3>
                <p>
                  At TechPulsar, we combine technical expertise with a deep
                  understanding of your business needs to create websites that
                  drive results. Our web development services are tailored to
                  help you achieve your online goals.
                </p>
                <p>
                  We focus on delivering high-quality, scalable websites that
                  are easy to maintain and update. Our commitment to client
                  satisfaction and excellence in web development sets us apart
                  as the best choice for your project.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Experienced Developers: </strong>Our team of skilled
                    developers has extensive experience in building custom
                    websites and applications.
                  </li>
                  <li>
                    <strong>Cutting-Edge Technology: </strong>We use the latest
                    tools and frameworks to ensure your website is fast, secure,
                    and scalable.
                  </li>
                  <li>
                    <strong>Client-Centric Approach: </strong>We work closely
                    with you to understand your business goals and deliver a
                    website that exceeds your expectations.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why Web Development is Essential
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  Web Development Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faqs />
    </section>
  );
}
