import React from "react";

export default function Footer() {
  return (
    <footer id="footer" class="footer accent-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-about">
            <a href="index.html" class="logo d-flex align-items-center">
              <span class="sitename">TechPulsar</span>
            </a>
            <p>
              We work in agile and follow the best practices to deliver quality,
              cost effective and professional IT Services & Solutions globally.
            </p>
            <div class="social-links d-flex mt-4">
              <a
                href="https://web.facebook.com/TechPulsarOfficial"
                target="_blank"
              >
                <i class="bi bi-facebook"></i>
              </a>
              <a href="https://www.pinterest.com/techpulsar/">
                <i class="bi bi-pinterest"></i>
              </a>
              <a href="">
                <i class="bi bi-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/techpulsar">
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <a href="/#hero">Home</a>
              </li>
              <li>
                <a href="/#about">About us</a>
              </li>
              <li>
                <a href="/#services">Services</a>
              </li>
              <li>
                <a href="/blog" target="_blank" rel="noopener noreferrer">
                  Blogs &nbsp;
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://tools.techpulsar.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tools &nbsp;
                  <i className="fas fa-external-link-alt"></i>
                </a>
              </li>
              <li>
                <a href="/terms-and-conditions">Terms of service</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy policy</a>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <a
                  href="/ui-ux-design"
                  aria-disabled="true"
                  onclick="return false;"
                >
                  UI/UX Design
                </a>
              </li>
              <li>
                <a
                  href="/wordpress-development"
                  aria-disabled="true"
                  onclick="return false;"
                >
                  Wordpress Development
                </a>
              </li>
              <li>
                <a
                  href="/website-development"
                  aria-disabled="true"
                  onclick="return false;"
                >
                  Web Development
                </a>
              </li>
              <li>
                <a
                  href="/app-development"
                  aria-disabled="true"
                  onclick="return false;"
                >
                  App Development
                </a>
              </li>
              <li>
                <a href="/seo" aria-disabled="true" onclick="return false;">
                  Search Engine Optimization
                </a>
              </li>
              <li>
                <a
                  href="/digital-marketing"
                  aria-disabled="true"
                  onclick="return false;"
                >
                  Digital Marketing
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <p>Office No. 209, Second Floor,</p>
            <p>Dubai Plaza, 6th Road</p>
            <p>Rawalpindi, 46000</p>
            <p>Pakistan</p>
            <p class="mt-4">
              <strong>Phone:</strong> <span>+92 333 8701 104</span>
              <br />
              <span style={{ marginLeft: "15%" }}>+92 309 3482 063</span>
            </p>
            <p>
              <strong>Email:</strong> <span>info@techpulsar.com</span>
            </p>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>{" "}
          <strong class="px-1 sitename">TechPulsar</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div class="credits">
          {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you've purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: [buy-url] -->
        <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> --> */}
          Powered by <a href="#">TechPulsar</a>
        </div>
      </div>
    </footer>
  );
}
