import React, { useEffect } from "react";

const LoadScripts = () => {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(script);
        script.onerror = () =>
          reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    const scripts = [
      "assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
      // "assets/vendor/php-email-form/validate.js",
      "assets/vendor/aos/aos.js",
      "assets/vendor/glightbox/js/glightbox.min.js",
      // "assets/vendor/purecounter/purecounter_vanilla.js",
      "assets/vendor/swiper/swiper-bundle.min.js",
      "assets/vendor/imagesloaded/imagesloaded.pkgd.min.js",
      "assets/vendor/isotope-layout/isotope.pkgd.min.js",
      // "assets/js/main.js",
    ];

    scripts.reduce((promise, script) => {
      return promise.then(() => loadScript(script));
    }, Promise.resolve());

    // Cleanup function to remove scripts if necessary
    return () => {
      scripts.forEach((src) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      });
    };
  }, []);

  return null; // This component does not render anything
};

export default LoadScripts;
