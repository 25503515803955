import React, { useState } from "react";

export default function Faqs() {
  // State to keep track of the currently open FAQ item
  const [openIndex, setOpenIndex] = useState(0);

  // Function to handle FAQ item click
  const handleToggle = (index) => {
    // Toggle FAQ item
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ data
  const faqs = [
    {
      question: "What services do you offer?",
      answer:
        "We provide a wide range of IT and software solutions, including web design, development, app development, SEO, cloud services, and more. Our goal is to tailor our services to meet your specific business needs.",
    },
    {
      question: "What is the cost of your services?",
      answer:
        "Pricing varies based on the services and complexity of the project. We offer customized quotes after assessing your needs and project scope. Contact us for a detailed and tailored proposal.",
    },
    {
      question: "How do you ensure the security of my website or application?",
      answer:
        "We implement industry-standard security measures, including encryption, regular updates, and vulnerability testing, to protect your website or application from threats and breaches.",
    },
    {
      question: "Can you help with digital marketing and SEO?",
      answer:
        "Absolutely! We offer comprehensive digital marketing and SEO services to enhance your online visibility, attract more visitors, and drive growth for your business.",
    },
    {
      question: "Will my website be mobile-friendly?",
      answer:
        "Yes, all of our websites are designed to be responsive and mobile-friendly, ensuring a seamless experience across all devices and screen sizes.",
    },
    {
      question: "Can you provide examples of your previous work?",
      answer:
        "Yes, we are happy to share case studies and examples of our past projects. This helps illustrate our capabilities and the quality of our work.",
    },
    {
      question: "What makes your company different from others?",
      answer:
        "We stand out by providing high-quality, customized solutions tailored to your specific needs, coupled with a strong focus on security and the protection of your assets. Our commitment to delivering secure, personalized services ensures that your digital projects are both effective and safeguarded.",
    },
  ];

  return (
    <section id="faq" className="faq section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Frequently Asked Questions</h2>
        <p>Your Questions Answered: Find What You Need to Know</p>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
            <div className="faq-container">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`faq-item ${
                    openIndex === index ? "faq-active" : ""
                  }`}
                >
                  <h3 onClick={() => handleToggle(index)}>{faq.question}</h3>
                  <div
                    className={`faq-content ${
                      openIndex === index ? "show" : ""
                    }`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                  <i
                    className={`faq-toggle bi bi-chevron-${
                      openIndex === index ? "down" : "right"
                    }`}
                    onClick={() => handleToggle(index)}
                  ></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
