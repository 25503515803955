import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Faqs from "../sections/faqs";

export default function WordpressPage() {
  const [activeTab, setActiveTab] = useState("introduction");

  useEffect(() => {
    // Set the initial active tab based on the URL hash
    const hash = window.location.hash.substring(1);
    if (hash) {
      setActiveTab(hash);
      scrollToSection(hash); // Scroll to the section initially if there's a hash
    }
  }, []);

  useEffect(() => {
    // Update the active tab when the URL hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const scrollToSection = (id) => {
    // Smooth scrolling to the section
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // Delay setting activeTab to ensure scrolling completes
      setTimeout(() => setActiveTab(id), 500);
    }
  };

  return (
    <section className="main">
      <Helmet>
        <title>WordPress Development | TechPulsar</title>
        <meta
          name="description"
          content="TechPulsar specializes in WordPress development, offering custom themes, plugin integration, and ongoing support to enhance your website's functionality and design."
        />
        <meta
          name="keywords"
          content="WordPress development, WordPress design, custom WordPress themes, WordPress plugins, website development"
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://techpulsar.com/wordpress-development"
        />

        {/* <!-- Open Graph Tags --> */}
        <meta
          property="og:title"
          content="WordPress Development | TechPulsar"
        />
        <meta
          property="og:description"
          content="Specializing in WordPress development, TechPulsar offers custom themes, plugins, and support to enhance your website's design and functionality."
        />
        <meta
          property="og:image"
          content="https://techpulsar.com/assets/img/services/wordpress1.jpg"
        />
        <meta
          property="og:url"
          content="https://techpulsar.com/wordpress-development"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="page-title accent-background">
        <div className="container position-relative">
          <h1>WordPress Development</h1>
          <p>Building Robust and Scalable Websites with WordPress</p>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li className="current">Services / WordPress Development</li>
            </ol>
          </nav>
        </div>
      </div>

      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8">
              <div id="introduction" data-aos="fade-up" data-aos-delay="200">
                <h3>Introduction to WordPress Development Services</h3>
                <p>
                  Elevate your online presence with TechPulsar's expert
                  WordPress development services. Our team creates custom
                  WordPress solutions that are both functional and visually
                  appealing, tailored to your business needs. From theme
                  development to plugin customization, we ensure your website
                  stands out and performs optimally.
                </p>
              </div>

              <img
                data-aos="fade-right"
                data-aos-delay="200"
                src="/assets/img/services/wordpress1.jpg"
                alt="WordPress development"
                style={{
                  width: "100%",
                  borderRadius: 10,
                  marginBottom: "3%",
                  height: "600px",
                }}
              />

              <div id="importance" data-aos="fade-up" data-aos-delay="300">
                <h3>Why WordPress Development is Essential</h3>
                <p>
                  In today’s digital landscape, a robust website is crucial for
                  business success. WordPress provides a versatile and scalable
                  platform that can be customized to meet any need. Whether
                  you're launching a new site or improving an existing one,
                  professional WordPress development ensures your site is
                  engaging, functional, and easy to manage.
                </p>
                <p>
                  With over 40% of websites on the internet using WordPress,
                  it’s clear that this platform is favored for its flexibility
                  and user-friendliness. Investing in professional WordPress
                  development helps leverage these benefits, driving more
                  traffic, engagement, and business growth.
                </p>
              </div>

              <h3 data-aos="fade-up" data-aos-delay="200">
                Detailed Service Offerings
              </h3>
              <div
                id="service-offerings"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h4>1. Custom Theme Development</h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We create custom WordPress themes tailored to your brand,
                  ensuring a unique and professional look for your site. Our
                  themes are responsive, SEO-friendly, and optimized for
                  performance.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  2. Plugin Development and Integration
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We develop and integrate plugins to enhance your site's
                  functionality. Whether you need custom features or third-party
                  plugins, we ensure seamless integration and performance.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  3. WooCommerce Solutions
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Our WooCommerce solutions help build and manage your online
                  store. From setup and customization to ongoing support, we
                  ensure your eCommerce site is user-friendly and optimized for
                  sales.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="200">
                  4. Ongoing Maintenance and Support
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  We provide ongoing maintenance and support to keep your
                  WordPress site secure and up-to-date. Our services include
                  regular updates, backups, and troubleshooting to ensure smooth
                  operation.
                </p>
              </div>

              <div id="top-tools">
                <h3 data-aos="fade-up" data-aos-delay="200">
                  Top Tools Used in WordPress Development
                </h3>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Elementor:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Elementor is a powerful page builder that allows us to create
                  custom layouts with ease. Its drag-and-drop interface
                  simplifies building visually appealing pages.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Advanced Custom Fields (ACF):
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  ACF provides advanced custom fields for content management,
                  allowing us to create custom post types, fields, and meta
                  boxes tailored to your needs.
                </p>

                <img
                  data-aos="fade-right"
                  data-aos-delay="300"
                  src="/assets/img/services/wordpress.jpg"
                  alt="WordPress development"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    marginBottom: "3%",
                  }}
                />

                <h4 data-aos="fade-up" data-aos-delay="300">
                  WPML:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  WPML allows us to create multilingual sites, expanding your
                  reach to a global audience. We use WPML to ensure your site is
                  accessible in multiple languages.
                </p>
                <h4 data-aos="fade-up" data-aos-delay="300">
                  Yoast SEO:
                </h4>
                <p data-aos="fade-up" data-aos-delay="300">
                  Yoast SEO helps us optimize your site for search engines,
                  improving on-page SEO, managing sitemaps, and enhancing
                  visibility online.
                </p>
              </div>

              <div id="why-techpulsar" data-aos="fade-up" data-aos-delay="300">
                <h3>Why TechPulsar is the Best Choice</h3>
                <p>
                  TechPulsar is committed to delivering exceptional WordPress
                  development services. Our team combines expertise, innovation,
                  and a client-focused approach to ensure top-quality results.
                </p>
                <p>
                  We offer tailored solutions, timely delivery, and ongoing
                  support to keep your WordPress site at its best. Our track
                  record of successful projects and satisfied clients highlights
                  our dedication to excellence.
                </p>
                <ul className="list-group">
                  <li>
                    <strong>Experienced Developers: </strong> Our team has
                    extensive experience in WordPress development, ensuring
                    reliable and high-quality solutions.
                  </li>
                  <li>
                    <strong>Custom Solutions: </strong> We tailor our services
                    to meet your specific needs, delivering unique and effective
                    results.
                  </li>
                  <li>
                    <strong>Comprehensive Support: </strong> We provide
                    end-to-end support, from development to maintenance,
                    ensuring your site remains secure and up-to-date.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="services-list">
                <a
                  href="#introduction"
                  onClick={() => scrollToSection("introduction")}
                  className={activeTab === "introduction" ? "active" : ""}
                >
                  Introduction
                </a>
                <a
                  href="#importance"
                  onClick={() => scrollToSection("importance")}
                  className={activeTab === "importance" ? "active" : ""}
                >
                  Why WordPress Development is Essential
                </a>
                <a
                  href="#service-offerings"
                  onClick={() => scrollToSection("service-offerings")}
                  className={activeTab === "service-offerings" ? "active" : ""}
                >
                  Service Offerings
                </a>
                <a
                  href="#top-tools"
                  onClick={() => scrollToSection("top-tools")}
                  className={activeTab === "top-tools" ? "active" : ""}
                >
                  Top Tools Used
                </a>
                <a
                  href="#why-techpulsar"
                  onClick={() => scrollToSection("why-techpulsar")}
                  className={activeTab === "why-techpulsar" ? "active" : ""}
                >
                  Why TechPulsar
                </a>
              </div>
              <h4>At TechPulsar,</h4>
              <p>
                We offer a comprehensive range of software development services
                designed to boost your business. From developing feature-rich
                WordPress sites to enhancing your online presence with SEO and
                digital marketing, we ensure every solution is customized to
                meet your specific goals. Our services also include app
                development, cloud management, and continuous integration for a
                complete digital experience.
              </p>
              <Faqs />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
