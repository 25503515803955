import React, { useEffect } from "react";
import Isotope from "isotope-layout";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.css";
import PortfolioItem from "../components/PortfolioItem";

export default function Work() {
  useEffect(() => {
    const isotopeInstance = new Isotope(".isotope-container", {
      itemSelector: ".portfolio-item",
      layoutMode: "masonry",
      filter: "*",
    });

    const filterButtons = document.querySelectorAll(".portfolio-filters li");

    filterButtons.forEach((button) => {
      button.addEventListener("click", function () {
        filterButtons.forEach((btn) => btn.classList.remove("filter-active"));
        this.classList.add("filter-active");

        const filterValue = this.getAttribute("data-filter");
        isotopeInstance.arrange({ filter: filterValue });
      });
    });

    const lightbox = GLightbox({
      selector: ".glightbox",
    });
  }, []);

  return (
    <section id="portfolio" className="portfolio section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Work</h2>
        <p>Delivering Innovative Solutions That Drive Your Success.</p>
      </div>

      <div className="container">
        <div
          className="isotope-layout"
          data-default-filter="*"
          data-layout="masonry"
          data-sort="original-order"
        >
          <ul
            className="portfolio-filters isotope-filters"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <li data-filter="*" className="filter-active">
              All
            </li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-website">Website</li>
            <li data-filter=".filter-template">Templates</li>
            <li data-filter=".filter-pos">POS</li>
          </ul>

          <div
            className="row gy-4 isotope-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <PortfolioItem
              imageSrc="assets/img/portfolio/website.webp"
              altText="LaVivaPay Website"
              title="LaVivaPay"
              filterClass="filter-website"
              externalLink="https://www.lavivapayinc.com/"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/app.png"
              altText="QR & NFC Scanner Inventory Management Tool"
              title="QR & NFC Scanner Inventory Management"
              filterClass="filter-app"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/template.webp"
              altText="Fashion X Template"
              title="Fashion X | Android & iOS Template"
              filterClass="filter-template"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/website1.webp"
              altText="Inception Tech Pk"
              title="Inception Tech Pk"
              filterClass="filter-website"
              externalLink="https://inceptiontech.com/"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/website2.webp"
              altText="Mereksols"
              title="Mereksols"
              filterClass="filter-website"
              externalLink="https://mereksols.com/"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/app1.png"
              altText="Drowsiness Detection"
              title="Drowsiness Detection"
              filterClass="filter-app"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/website3.webp"
              altText="Hamayl Design Hub"
              title="Hamayl Design Hub"
              filterClass="filter-website"
              externalLink="https://hamayldesignerhub.com/shop/"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/website4.png"
              altText="Dejani Resort"
              title="Dejani Resort"
              filterClass="filter-website"
              externalLink="https://resort-app-vercel-frontend.vercel.app/"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/template1.png"
              altText="Recifeed Template"
              title="Recifeed | Android & iOS Template"
              filterClass="filter-template"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/template2.webp"
              altText="Art Generator Template"
              title="Art Generator | Android & iOS Template"
              filterClass="filter-template"
            />
            <PortfolioItem
              imageSrc="assets/img/portfolio/soon.jpg"
              altText="Coming Soon"
              title="Coming Soon"
              filterClass="filter-pos"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
