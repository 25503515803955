import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
};

export const logPageView = () => {
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category = "", action = "", label = "") => {
  if (category && action && label) {
    ReactGA.event({ category, action, label });
  }
};
