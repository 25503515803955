import React from "react";
import GLightbox from "glightbox";

export default function PortfolioItem({
  imageSrc,
  altText,
  title,
  link,
  filterClass,
  externalLink,
}) {
  return (
    <div
      className={`col-lg-3 col-md-6 portfolio-item isotope-item ${filterClass}`}
    >
      <div className="portfolio-content">
        <a
          href={imageSrc}
          data-gallery="portfolio-gallery-app"
          className="glightbox"
        >
          <img
            src={imageSrc}
            className="img-fluid"
            style={{ width: "100%", height: "300px" }}
            alt={altText}
          />
        </a>
        <div className="portfolio-info">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-0">{title}</h4>
            {externalLink && (
              <a
                href={externalLink}
                target="_blank"
                rel="noopener noreferrer"
                title="More Details"
              >
                <i
                  className="bi bi-link-45deg"
                  style={{ fontSize: "30px" }}
                ></i>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
