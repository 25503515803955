import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AOS from "aos";
import LoadScripts from "./components/loadScripts";
import Navbar from "./sections/navbar";
import HomePage from "./pages/HomePage";
// import ServiceDetails from "./pages/ServiceDetails";
import Footer from "./sections/footer";
import UiuxPage from "./pages/UiuxPage";
import WordpressPage from "./pages/WordpressPage";
import SeoPage from "./pages/SeoPage";
import WebDevelopmentPage from "./pages/WebDevelopmentPage";
import AppDevelopmentPage from "./pages/AppDevelopmentPage";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";
import GetQuote from "./pages/GetQuote/GetQuote";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermConditions";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init();

    const scrollTopButton = document.getElementById("scroll-top");

    const toggleScrollTopButton = () => {
      if (window.scrollY > 300) {
        scrollTopButton.classList.add("active");
      } else {
        scrollTopButton.classList.remove("active");
      }
    };

    window.addEventListener("scroll", toggleScrollTopButton);

    scrollTopButton.addEventListener("click", (event) => {
      event.preventDefault(); // Prevent the default anchor behavior
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    return () => {
      window.removeEventListener("scroll", toggleScrollTopButton);
    };
  }, []);

  const isErrorPage = location.pathname === "*";

  return (
    <div>
      <div className="index-page light-background">
        <LoadScripts />
        {!isErrorPage && <Navbar />}
        <main className="main">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/ui-ux-design" element={<UiuxPage />} />
            <Route path="/wordpress-development" element={<WordpressPage />} />
            <Route path="/seo" element={<SeoPage />} />
            <Route
              path="/website-development"
              element={<WebDevelopmentPage />}
            />
            <Route path="/app-development" element={<AppDevelopmentPage />} />
            <Route
              path="/digital-marketing"
              element={<DigitalMarketingPage />}
            />
            <Route path="/get-quote" element={<GetQuote />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/blog/*"
              component={() => (window.location.href = "/blog/index.php")}
            />
            <Route path="*" element={<ErrorPage />} />
            {/* <Route path="/services-details" element={<ServiceDetails />} /> */}
            {/* Add more routes here for future pages */}
          </Routes>
        </main>
        {!isErrorPage && <Footer />}

        {/* Scroll Top */}
        <a
          href="#"
          id="scroll-top"
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short"></i>
        </a>

        <a
          href="https://wa.me/+923338701104"
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp Chat"
          />
        </a>

        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&to=info@techpulsar.com&su=Your+Subject+Here&body=Your+message+here"
          className="email-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="bi bi-envelope-at" style={{ fontSize: "30px" }}></i>
        </a>
      </div>
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
